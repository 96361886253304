<template>
  <div class="bg-white p-4 flex flex-col h-full">
    <header
      v-if="selected.length > 0"
      class="
        mdc-top-app-bar mdc-top-app-bar--fixed
        bg-gray-darker
        fixed
        top-0
        left-0
        right-0
        z-50
      "
    >
      <div class="mdc-top-app-bar__row max-w-screen-xl px-8">
        <section
          class="mdc-top-app-bar__section mdc-top-app-bar__section--align-start"
        >
          <div class="hidden md:inline w-48" />
          <span class="flex items-center">
            <i
              class="
                material-icons
                cursor-pointer
                mr-4
                p-2
                rounded-full
                hover:bg-gray
              "
              @click="toggleAll(false)"
              >clear</i
            >
            <span v-text="selected.length + ' selected'" />
          </span>
        </section>
        <section
          class="
            mdc-top-app-bar__section
            mdc-top-app-bar__section--align-end
            mdc-top-app-bar__section--shrink-to-fit
          "
        >
          <span v-if="$store.state.email.pagination.view !== 'trash'">
            <mdc-button
              title="Trash selected"
              raised
              danger
              @click.native="trashSelected('trash')"
            >
              <i class="material-icons">delete</i>
            </mdc-button>
          </span>
          <span v-else>
            <mdc-button
              title="Restore from trash"
              raised
              secondary
              class="ml-2"
              @click.native="trashSelected('restore')"
            >
              <i class="material-icons">restore_from_trash</i>
            </mdc-button>
            <mdc-button
              title="Delete Forever"
              raised
              danger
              class="ml-2"
              @click.native="trashSelected('delete')"
            >
              <i class="material-icons">delete_forever</i>
            </mdc-button>
          </span>
        </section>
      </div>
    </header>

    <div
      v-if="$store.getters.verifiedAccounts.length === 0"
      class="text-gray-dark flex items-center"
    >
      <i class="material-icons">arrow_left</i>
      <span
        >Verify your <router-link to="/account/email">account</router-link> to
        begin!</span
      >
    </div>

    <div class="overflow-x-auto shadow rounded border">
      <table :style="{ minWidth: '800px' }" class="later-table w-full text-sm">
        <thead>
          <tr>
            <th class="w-12">
              <mdc-checkbox :value="areAllChecked" @input="toggleAll" />
            </th>
            <th class="w-64 text-left">To</th>
            <th class="w-full cursor-pointer" @click="setSort('subject')">
              <later-col-sort
                :sort="$store.state.email.pagination.sort"
                by="subject"
                left
              >
                Subject
              </later-col-sort>
            </th>
            <th
              class="w-32 cursor-pointer text-center"
              @click="setSort('updated_at')"
            >
              <later-col-sort
                :sort="$store.state.email.pagination.sort"
                by="updated_at"
              >
                Last Modified
              </later-col-sort>
            </th>
            <th class="w-32 cursor-pointer" @click="setSort('send_at')">
              <later-col-sort
                :sort="$store.state.email.pagination.sort"
                by="send_at"
              >
                Send Date
              </later-col-sort>
            </th>
            <th class="w-32">Status</th>
          </tr>
        </thead>
        <tbody v-if="emails" class="bg-white">
          <tr
            v-for="email in emails.data"
            :key="email.hash"
            :class="
              email.hidden && email.status === 'scheduled'
                ? 'bg-gray-lighter'
                : 'cursor-pointer'
            "
            :title="
              email.hidden
                ? 'This email will be in the vault until it has been sent!'
                : ''
            "
            @click="openEmail(email)"
          >
            <td class="cursor-auto" @click.stop>
              <mdc-checkbox v-model="email.checked" />
            </td>
            <td>
              <div
                v-if="email.first_recipient"
                :class="{ 'line-through': email.first_recipient.deleted_at }"
                class="flex"
              >
                <span class="flex items-center truncate">
                  <span
                    v-if="email.first_recipient.email"
                    :title="email.first_recipient.email"
                  >
                    <span
                      v-if="
                        email.first_recipient.name &&
                        email.first_recipient.name.length > 0
                      "
                      v-text="email.first_recipient.name"
                    />
                    <span v-else v-text="email.first_recipient.email" />
                  </span>
                  <span
                    v-else
                    class="flex items-center"
                    title="A group of contacts"
                  >
                    <i class="material-icons text-lg mr-1">group</i>
                    <span v-text="email.first_recipient.name" />
                  </span>
                  <span
                    v-if="email.recipients_count > 1"
                    class="mx-1 text-gray"
                    v-text="`(${email.recipients_count})`"
                  />
                </span>
              </div>
            </td>
            <td class="truncate">
              <span v-text="email.subject" />
              <template v-if="!email.hidden">
                <span
                  v-if="email.subject.length > 0"
                  class="text-gray"
                  v-text="' - '"
                />
                <span class="text-gray" v-text="email.body" />
              </template>
            </td>
            <td class="text-center">
              <later-date v-model="email.updated_at" />
            </td>
            <td class="text-center">
              <later-date v-model="email.send_at" />
              <div
                v-if="email.recur_obj"
                :title="recurString(email.recur_obj)"
                class="relative"
              >
                <i
                  :style="{ right: '0', bottom: '0' }"
                  class="material-icons text-base absolute"
                  >replay</i
                >
              </div>
            </td>
            <td class="text-center">
              <later-status
                :status="email.deleted_at ? 'trash' : email.status"
              />
              <div
                v-if="email.attachments_count > 0"
                :title="`${email.attachments_count} attachment(s)`"
                class="relative"
              >
                <i
                  :style="{ right: '.5rem', bottom: '0' }"
                  class="material-icons text-base absolute"
                  >attachment</i
                >
              </div>
              <div
                v-if="email.logs_failed_count > 0"
                :title="`${email.logs_failed_count} failed recipient(s).  Check the logs for more info.`"
                class="relative"
                @click.stop="$router.push(`/email/logs/${email.hash}`)"
              >
                <i
                  :style="{ right: '0', bottom: '0' }"
                  class="material-icons text-base absolute text-error"
                  >error</i
                >
              </div>
              <div v-else-if="email.hidden" class="relative">
                <i
                  :style="{ right: '0', bottom: '0' }"
                  class="material-icons text-base absolute"
                  v-text="email.status === 'scheduled' ? 'lock' : 'lock_open'"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <later-pagination
      v-if="emails"
      v-model="emails"
      module="email"
      class="self-end"
    />

    <div class="mt-auto">
      <blockquote class="note mt-8 text-sm">
        <p v-if="$store.state.email.pagination.view !== 'trash'">
          Emails will be moved to the trash 30 days after they have been sent.
        </p>
        <p v-else>
          Emails will be deleted permanently 30 days after they have been sent
          to the trash.
        </p>
      </blockquote>
    </div>
  </div>
</template>

<script>
import axios from '@/utils/xhr';
import { recurString } from '@/utils/date';
import MdcButton from '@/components/mdc/button.vue';
import MdcCheckbox from '@/components/mdc/checkbox.vue';
import LaterColSort from '@/components/col-sort.vue';
import LaterDate from '@/components/date.vue';
import LaterPagination from '@/components/pagination.vue';
import LaterStatus from '@/components/status.vue';

export default {
  components: {
    LaterColSort,
    LaterDate,
    LaterPagination,
    LaterStatus,
    MdcButton,
    MdcCheckbox,
  },

  data: () => ({
    emails: null,
    unsubscribe: null,
  }),

  computed: {
    selected() {
      return this.emails == null
        ? []
        : this.emails.data.filter((email) => email.checked);
    },
    areAllChecked() {
      return (
        this.selected.length > 0 &&
        this.selected.length === this.emails.data.length
      );
    },
  },

  mounted() {
    this.fetch();

    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (
        mutation.type === 'email/setView' ||
        mutation.type === 'email/setSearch' ||
        mutation.type === 'email/nextPage'
      )
        this.fetch();
    });
  },

  beforeDestroy() {
    this.unsubscribe();
  },

  methods: {
    recurString,
    fetch() {
      this.toggleAll(false);
      axios
        .post('/email/results', { ...this.$store.state.email.pagination })
        .then((response) => {
          response.data.data.forEach((email, i) => {
            response.data.data[i].checked = false;
          });
          document.body.scrollTop = 0;
          this.$set(this, 'emails', response.data);
          this.$store.commit('email/setTotal', response.data.total);
        });
    },
    openEmail(email) {
      if (email.hidden && email.status === 'scheduled') return;
      this.$router.push(`/email/${email.hash}`);
    },
    setSort(by) {
      this.$store.commit('email/setSort', by);
      this.fetch();
    },
    toggleAll(check) {
      let reallyCheck = check;
      if (!this.emails) return;
      if (typeof check !== 'boolean') reallyCheck = !this.areAllChecked;
      this.emails.data.forEach((email, i) => {
        this.emails.data[i].checked = reallyCheck;
      });
    },
    trashSelected(action) {
      const count = this.selected.length;
      if (count < 1) return;
      axios
        .post('/email/trash', {
          emails: this.selected.map((email) => email.hash),
          action,
        })
        .then(() => {
          let msg = `${count} email${count !== 1 ? 's have' : ' has'} been `;
          if (action === 'trash') msg += 'moved to the trash.';
          if (action === 'restore') msg += 'restored.';
          if (action === 'delete') msg += 'deleted forever.';
          this.$store.commit('snackbarMessage', msg);
          this.fetch();
        });
    },
  },
};
</script>
